import React, { lazy } from "react";
import { Route, Routes } from 'react-router-dom'

const IndexTictactoe = lazy(() => import("../games/tic-tac-toe/indexTictactoe"));
const HomePage = lazy(() => import("../pages/HomePage/HomePage"));
const SnakeGame = lazy(() => import("../games/snake/components/organisms/SnakeGame/SnakeGame"));
const PrivacyPolicyPage = lazy(() => import("../pages/PrivacyPolicyPage/PrivacyPolicyPage"));

const RoutersCustomer = () => {
  return (
    <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/tic-tac-toe" element={<IndexTictactoe />} />
          <Route path="/snake" element={<SnakeGame />} />
          <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
    </Routes>
  )
}

export default RoutersCustomer