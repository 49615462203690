import React from "react";
import separatorBottom from "../../../assets/images/separator_002.webp";
import brand from "../../../assets/images/logo_chirrigames_h.webp";
import brainMeg from "../../../assets/images/logo_brainmeg.webp";

const Footer = () => {
    return (
        <React.Fragment>
            <div className="w-full h-[700px] lg:h-[1200px] bg-footer-background bg-fixed bg-cover bg-bottom bg-no-repeat">
                <div className="w-full lg:w-6/12 h-auto m-auto" width={1399} height={262} alt="separator">
                    <img src={separatorBottom}></img>
                </div>
                <div className="text-center mt-10">
                    <p className="text-white">
                        info@chirrigames.com
                    </p>
                </div>
                <div className="">
                    <img className="w-[400px] my-20 mx-auto" width={725} height={314} src={brand}></img>
                </div>
            </div>
            <div className="w-full h-20 bg-black flex justify-end items-center px-10">
                <p className="text-white mr-5 text-xs">
                    Powered by:
                </p>
                <a href="https://brainmeg.com/" Target="_blank">
                    <img className="w-[100px] h-[auto]" width={205} height={60} src={brainMeg}></img>
                </a>
            </div>
        </React.Fragment>
    )
}
export default Footer;
