import React from 'react';
import NavLink from '../../atoms/NavLink/NavLink';
import { navigationJSON } from '../../../core/data/navigationJSON';

function NavLinksGroup() {
    return (
        <div className="flex justify-between">
            {
                navigationJSON.map((x) => {
                    return (
                        <NavLink href={x.href} key={x.name}>{x.name}</NavLink>
                    )
                })
            }
        </div>
    );
}

export default NavLinksGroup;
