import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import UIBrand from '../../atoms/Brand/UIBrand';
import NavLinksGroup from '../../molecules/NavLinksGroup/NavLinksGroup';
import NavLink from '../../atoms/NavLink/NavLink';
import TermsAndConditionsModal from '../TermsAndConditionsModal/TermsAndConditionsModal';
import logo from "../../../assets/images/logo_icon.webp";
function Navbar() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!mobileMenuOpen);
  };
  const closeMobileMenu = () => {
    setMobileMenuOpen(false);
  };

  const [termsOpen, setTermsOpen] = useState(false);
  const navigate = useNavigate();

  return (
    <nav className="relative w-full ">
      {/* Menú móviles */}
      <div className="lg:hidden">
        <div className="w-full h-20 bg-[#000000cc] mx-auto fixed top-8 left-0 right-0 z-50">
          <div className="w-full h-full flex items-center justify-between">
            <div className="w-full h-full flex justify-between items-center p-4">
              <div > 
                <UIBrand />
              </div>    
              <div>
                <button
                  className="text-white focus:outline-none text-3xl hover:text-blue-400"
                  onClick={toggleMobileMenu}
                >
                  ☰
                </button>         
              </div>    
              {mobileMenuOpen && (
                <div 
                  className='w-full h-screen lg:hidden absolute top-[-2rem] right-0 left-0 bg-[#000000cc] cursor-pointer'
                  onClick={closeMobileMenu}
                >
                  <div 
                    onClick={(e) => e.stopPropagation()}
                    className="flex flex-col text-center w-1/2 h-screen bg-menu-background bg-cover bg-center lg:hidden absolute top-0 right-0 rounded-l-3xl"
                  >
                    <div className='p-4 flex justify-end'>
                      <button 
                        className="text-white focus:outline-none p-2 rounded-full bg-slate-600 w-10 h-10 font-bold"
                        onClick={closeMobileMenu}
                      >
                        X
                      </button>
                    </div>
                    <hr className='w-[80%] border-cyan-800 mx-auto' />
                    <div className='flex flex-col gap-8 py-6'>
                      <NavLink 
                        onClick={closeMobileMenu} children={"Inicio"} href={"/"}
                      />                     
                      <NavLink 
                        onClick={closeMobileMenu} children={"Crea tu cuenta"} href={"#profile"}
                      />  
                      <NavLink 
                        onClick={closeMobileMenu} children={"Juegos"} href={"#games"}
                      />  
                      <div className='w-auto text-center'>
                        <button
                          className="text-white hover:text-gray-300 px-3 py-2 rounded-md text-sm font-medium"
                          onClick={() => setTermsOpen(true)}
                        >
                          Términos
                        </button>
                      </div>
                      <NavLink 
                        onClick={closeMobileMenu} children={"Nosotros"} href={"#about"} 
                      />
                      <NavLink 
                        onClick={closeMobileMenu} children={"Contacto"} href={"#contact"} 
                      />                    
                    </div>
                    <hr className='w-[80%] border-cyan-800 mx-auto' />
                    <img className='mx-auto w-[60%] h-auto mt-10' width={601} height={680} src={logo}></img>
                  </div>
                </div>
              )}
            </div>                
          </div>
        </div>
      </div>
      {/* Menú para escritorio */}
      <div className="hidden lg:flex justify-between items-center w-full">
        <div className="flex items-center w-full bg-transparent h-20 px-40 mx-auto absolute top-20 lg:top-[150px] left-0 right-0 z-50">
          <div className="w-full flex items-center justify-between h-16 border rounded-full border-stone-100 bg-[#00000020]">
            {/* <div className='w-2/5 px-10'>
              <NavLinksGroup />
            </div> */}
            <div className='w-2/5 flex justify-between items-center px-10'>
              <div className='w-auto text-center'>
                <NavLink children={"Inicio"} href={"/"} />                
              </div>
              <div className='w-auto text-center'>
                <NavLink children={"Crea tu cuenta"} href={"#profile"} />                
              </div>
              <div className='w-auto text-center'>
                <NavLink children={"Juegos"} href={"#games"} />                
              </div>                
            </div>
            <div className='w-1/5 pt-[7%]'>
              <UIBrand  />
            </div>
            <div className='w-2/5 flex justify-between items-center px-10'>
              <div className='w-auto text-center'>
                <button
                  className="text-white hover:text-blue-300 px-3 py-2 rounded-md text-sm font-medium"
                  onClick={() => setTermsOpen(true)}
                >
                  Términos
                </button>
              </div>
              <div className='w-auto text-center'>
                <NavLink children={"Nosotros"} href={"#about"} />                
              </div>
              <div className='w-auto text-center'>
                <NavLink children={"Contacto"} href={"#contact"} />                
              </div>                
            </div>
          </div>
        </div>
      </div>    
      <TermsAndConditionsModal isOpen={termsOpen} onClose={() => setTermsOpen(false)} />  
    </nav>
  );
}

export default Navbar;
