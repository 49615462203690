import React from "react";
import brandMobile from "../../../assets/images/logo_chirrigames_h.webp";
import brandDesktop from "../../../assets/images/logo_chirrigames.webp";

const UIBrand =()=>{
    return (
        <React.Fragment>
            <picture>
                <source type="image/webp" sizes="(max-width: 600px) 100vw, 50vw" />
                <img className="w-[300px] h-auto block lg:hidden" src={brandMobile} width={725} height={314}  alt="Chirrigames" sizes="(max-width: 600px) 100vw, 50vw"></img>
                <img className="w-full hidden lg:block" src={brandDesktop} width={515} height={757} alt="Chirrigames" sizes="(max-width: 600px) 100vw, 50vw"></img>
            </picture>
        </React.Fragment>
    )
}
export default UIBrand;