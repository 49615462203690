import React, { Suspense } from "react";
import { BrowserRouter as Router} from 'react-router-dom';
import './App.css';
import RoutersCustomer from './routers/routersCustomer';
import SkeletonLoader from "./pages/SkeletonLoader/SkeletonLoaderView";
import Navbar from "./components/organisms/Navbar/Navbar";
import BannerHero from "./components/organisms/Banners/BannerHero";
import Footer from "./components/organisms/Footer/Footer";

export default function App() {
  return (
    <Router>
      <div className="App">
        <Suspense fallback={<SkeletonLoader />}>
            <Navbar />
            <RoutersCustomer />
            <Footer />
        </Suspense>
      </div>
    </Router>
  );
}
