import React from 'react';
import { Link } from 'react-router-dom';

function NavLink({ href, children, onClick, }) {
  // Si el href comienza con #, es un ancla interna
  const isAnchor = href.startsWith('#');

  if (isAnchor) {
    return (
      <a
        href={href}
        className="text-white hover:text-blue-300 px-3 py-2 rounded-md text-sm font-medium"
        onClick={onClick}
      >
        {children}
      </a>
    );
  } else {
    // Si no, utiliza Link de React Router
    return (
      <Link
        to={href}
        className="text-white hover:text-blue-300 px-3 py-2 rounded-md text-sm font-medium"
        onClick={onClick}
      >
        {children}
      </Link>
    );
  }
}

export default NavLink;
