import React from 'react';
import Button from '../../atoms/Button/Button';
import Modal from '../../molecules/Modal/Modal';

const TermsAndConditionsModal = ({ isOpen, onClose }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <div className="text-sm text-gray-500">
        <p>Este es un resumen de los términos y condiciones...</p>
        <div className="mt-4">
          <Button label="Acepto" onClick={onClose} />
        </div>
      </div>
    </Modal>
  );
};

export default TermsAndConditionsModal;
