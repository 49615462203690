import React from "react";

const BannerHero =()=>{
    return (
        <React.Fragment>
            <div className="h-[300px] lg:h-[650px] flex items-end justify-center pb-10">
                <p className="text-white">
                    Ingresa a tu juego preferido:
                </p>                
            </div>
        </React.Fragment>
    )
}
export default BannerHero;