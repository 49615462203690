import React from 'react';
import './SkeletonLoader.css'; // Importa el archivo de estilos CSS

const SkeletonLoader = () => {
  return (
    <div className="skeleton-loader">
      <div className="skeleton-header"></div>
      <div className="skeleton-content"></div>
      <div className="skeleton-footer"></div>
    </div>
  );
};

export default SkeletonLoader;